.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Set the background color to light grey */
body {
  background-color: white;
}

/* Set the font and text color */
body, button, input {
  font-family: 'Roboto', sans-serif;
  color: #212121;
}

/* Center the main content and make it 80% width */
.container {
  margin: 0 auto;
  max-width: 80%;
}

/* Add some spacing between the sections */
.section {
  margin-bottom: 5rem;
}

/* Style the logo */
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.logo img {
  max-height: 7rem;
}

/* Style the "Learn More" button */
.button {
  background-color: #212121;
  color: #ffffff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button:hover {
  background-color: #ffffff;
  color: #212121;
}


.nav-bar-logo {
  /*width: 300px;*/
  width: calc(250px + 8.5vmin);
  padding-left: 20px;
}

.logo-color{fill:#3d3d3d;}
.logo-primary-text{fill:#ff993a;font-size:81.23px;font-family:Roboto-Bold, Roboto;font-weight:700; letter-spacing:1em;}
.logo-secondary-text{fill:#fff;font-size:81.23px;font-family:Roboto-Bold, Roboto;font-weight:700; letter-spacing:-0.02em;}

.navbar-animate {
  animation: fadein 2s forwards;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* original fadein
@keyframes fadein {
  from { background-color: #ffffff;}
  to   { background-color: #7ea8c1;}
}
*/

@keyframes fadein {
  from { background-color: #0958A1;}
  to   { background-color: #ffffff;}
}

.navbar {
  /*background-color: #7ea8c1;*/
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 .0025rem .15rem rgba(0, 0, 0, 0.219);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: auto;
  padding-right: 10%;
}

.nav-links li {
  margin-right: 50px;
}

.nav-links li a {
  float:left;
}

.nav-links li:last-child {
  margin-right: 0;
}

.nav-links li .top-level-nav-item {
  color: #575757;
  font-weight: none;
  text-decoration: none;
}

.nav-links li .top-level-nav-item:hover {
  text-decoration: underline;
}

.dropdown-menu.show {
  display: flow-root;
}

p {
  text-align: left;
}

.login-form {
  text-align: left;
}

.button-group {
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;
}

.button-group span {
  float: left;
  margin: 0 5px 0 0;
  width: 200px;
  height: 40px;
  position: relative;
}

.button-group label,
.button-group input  {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.button-group input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}

.button-group input[type="radio"]:checked+label,
.Checked+label {
  background: yellow;
}

.button-group label {
  padding: 10px;
  border: 1px solid #CCC;
  cursor: pointer;
  z-index: 90;
}

.button-group span:hover {
  background: #DDD;
}

.footer {
  background-color: #EEF4F9;
  min-height: 10vh;
}

.footer .copyright {
  font-weight: bold;
  font-size: 10px;
  padding: 20px 0 20px 0;
}

.about-page, .buffer  {
  margin-top: 30px;
}

.landing-page {
  background: white;
  margin-top:8%;
}

.landing-art {
  width: 60%;
}

.landing-tagline {
  font-weight: 900;
  font-size: calc(1.525rem + 3.3vw);
  text-align: left;
  line-height: .9;
}

.welcome-title {
  font-weight: bold;
}

.btn-primary{
  background-color: #0958A1;
  border-color: #0958A1;
}

.btn-outline-primary{
  background-color: white;
  border-color: #0958A1;
  color: #0958A1;
}

.btn-primary:hover{
  background-color: #073f74;
  border-color: #073f74;
  color:white;
}

.btn-special {
  background-color: #D7E7F6;
  border-color: #D7E7F6;
  color: #0958A1;
  font-size: .8rem;
  padding: .375rem 2rem;
}

.btn-special:hover {
  background-color: #c6d6e6;
  border-color: #c6d6e6;
  color: #0958A1;
}

.landing-page-middle {
  background-color: #0958A1;
  color: white;
  padding: 8% 0 8% 0;
}

.landing-middle-tagline {
  text-align: center;
  font-weight: 600;
  font-size: calc(1.225rem + 1.3vw);
}

.landing-page-middle .card {
  color: black;
  border-radius: 30px;
  width: 250px;
  margin: 20px auto 20px auto;
}

.landing-page-middle .card-img-top {
  width: 100px;
  margin: 40px auto 20px auto;
}

.landing-page-middle .card-title {
  font-weight: bold;
  font-size: 1rem;
}

.landing-page-middle .card-text {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
}

.materials-list {
  margin-top: -150px;
}

.project_summary_box{
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  background-color: #f8f9fa; /* Optional background color */
  margin-bottom: 10px;
  list-style: none;
  font-size: 15px;
}

.list-group-item {
  border: none;
  padding: 10px 20px;
  background-color: #f8f9fa; /* Optional background color */
  display: flex; /* Add display flex to each list item */
  justify-content: space-between; /* Space between spans */
  align-items: center; /* Center spans vertically */
  text-align:left;
}


/* Optional styles for the spans */
.list-group-item > span:first-child {
  font-weight: bold;
}

.list-group-item > span:last-child {
  font-weight: bold;
  color: #007bff; /* Optional color for the second span */
}

ul {
  padding: 0px;
}

/* CSS */
.unselected-image {
  filter: grayscale(100%);
}


.material-item{
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  background-color: #fff;
}

.material-image {
  max-width: 80px;
  margin-right: 10px;
}

.material-details {
  flex-grow: 1;
}

.material-name {
  font-size: 1.2rem;
  margin: 0;
}

.material-description {
  color: #777;
  margin: 0;
}

.material-quantity-price {
  display: flex;
  justify-content: space-between;
}

.material-quantity,
.material-price {
  font-weight: bold;
}

.progress-group {
  display: flex;
  align-items: center;
  
}

.progress-group button {
  margin-right: 100px;
}

.progress {
  width: 30px; /* Adjust as needed */
  height: 30px; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ddd; /* Change to your desired background color */
  margin-right: 10px; /* Adjust as needed */
}

.progress-active {
  background-color: #0958A1; /* Change to your desired active color */
  color: white;
}

.sidebar {
  background-color: #0958A1; /* Change to your desired active color */
  color: white;
}

.sidebar a {
  color: white;
}

.sidebar a:hover, .sidebar a:focus {
  color: rgb(190, 190, 190);
}

.project-banner{
  background-color: #cbe7ff47;
  background-image: url(./resources/project_page/bathroom_project_banner.png);
  background-size: contain;
  background-position: center;
  background-blend-mode: multiply;
  color: #fff;
  min-height: 30vh;

}


.pre-scrollable {
  height: 240px;
  overflow-y: scroll;
}